


































































import {
  BTable, BButton, BCard, BRow, BCol, BFormDatepicker,
} from 'bootstrap-vue'
import dayjs from 'dayjs';

export default {
  name: "ReportingGenerators",
  components: {
    BTable,
    BButton,
    BFormDatepicker,
    BCard,
    BRow,
    BCol
  },
  data() {
    return {
      fields: [
        'id',
        'name',
        {
          key: 'show_form',
          label: "Form"
        }],
      items: [

      ],
      templates: [],
      generator: {}
    }
  },
  async mounted() {
    await this.getItems();
    await this.getTemplates();
  },
  methods: {
    templateName(templateId: string) {
      const find = (this.templates.find(x => x.id === templateId) as ReportingAPI.Templates.Item);
      return find?.name;
    },
    async getTemplates() {
      this.$set(this, 'templates', await this.$api.reporting.templates.GetList());
    },
    async getItems() {
      const items = await this.$api.reporting.generators.GetList();

      for (const item of items) {
        this.$set(this.generator, item.id, {dateFrom: dayjs().add(-1, "month").format('YYYY-MM-DD'), dateTo: dayjs().format('YYYY-MM-DD'),})
      }

      this.$set(this, 'items', items);
    },
    async goTo(identifier: string) {
      await this.$router.push({
        name: "reporting-generator",
        params: {
          identifier
        }
      })
    },
    async generate(id: string) {
      const generator = this.generator[id];
      const result = await this.$api.reporting.generators.RequestGeneration(id, generator.dateFrom, generator.dateTo);
      this.$toast.success("Report is being generated, please wait for an email to be sent.");
    }
  },
  computed: {
    itemsSplitByTemplate() {
      const items = this.items;
      const itemsSplitByTemplate = {};
      for (const item of items) {
        if (!itemsSplitByTemplate[item.templateId]) {
          itemsSplitByTemplate[item.templateId] = [];
        }
        itemsSplitByTemplate[item.templateId].push(item);
      }
      return itemsSplitByTemplate;
    }
  }
}
